/* Card.css */
.card-container {
    display: flex;
    justify-content: space-around;
    flex-wrap: wrap;
    margin: 0 -10px;
  }
  
  .card {
    padding: 20px;
    margin: 10px;
    border: 1px solid #ccc;
    border-radius: 8px;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
    text-align: center;
    flex: 0 0 calc(33.333% - 20px); /* Use 0 0 to prevent cards from growing or shrinking */
  }
  
  .card-icon {
    font-size: 50px;
  }
  
  .card-title {
    margin-top: 10px;
    margin-bottom: 10px;
    color: rgb(110, 110, 110); /* grey color for text */
  }
  
  .card-content {
    margin-bottom: 10px;
    color: rgb(110, 110, 110); /* grey color for text */
  }
  
  /* Responsive styling for small screens */
  @media (max-width: 768px) {
    .card {
      flex-basis: 100%; /* Full width on small screens */
      margin: 10px 0;
    }
  
    .card-container {
      flex-direction: column;
    }
  }
  