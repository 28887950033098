body {
  font-family: 'Nunito', sans-serif;
  margin: 0;
  padding: 0;
  color: rgb(110, 110, 110); /* Default text color to white for better contrast */
}

.header {
  background-image: url('tomeswide.png');; /* Replace with your actual image path */
  background-size: cover;
  background-position: center;
  text-align: center;
  padding: 100px 0; /* Adjust padding to fit your design */
}

.logo {
  max-width: 120px; /* Adjust based on your logo */
  margin-bottom: 20px;
}

.title {
  color: #fff;
  font-size: 3rem;
  font-weight: 700;
  margin-bottom: 20px;
}

.description {
  color: #fff;
  font-size: 1.25rem;
  margin-bottom: 0;
}

.header {
  background-image: url('tomeswide.png');
  background-size: cover;
  background-position: center;
  text-align: center;
  padding: 100px 0; /* Adjust padding to fit your design */
  position: relative; /* Added relative positioning here */
}

/* This will ensure the overlay only covers the header */
.header::after {
  content: '';
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-image: linear-gradient(to bottom, rgba(0,0,0,0.1), rgba(0,0,0,0.2));
  z-index: 1;
}

.header-content {
  position: relative;
  z-index: 2; /* Make sure the content is above the overlay */
}


.tagline {
  margin-top: 20px;
  color: #AAA; /* Lighter gray for tagline */
  font-style: italic;
  font-weight: 300;
}

h1, h2 {
  color: rgb(110, 110, 110); /* grey color for text */
  font-weight: 600;
}

p {
  color: rgb(110, 110, 110); /* grey color for links */
  line-height: 1.6;
}

a {
  color: rgb(110, 110, 110); /* grey color for links */
  text-decoration: none;
  transition: color 0.3s; /* Smooth transition for hover effects */
}

a:hover {
  color:  rgb(44, 44, 44); /* White for hover effect */
}

.navbar-logo {
  padding-right: 20px; /* Adds padding to the right of the logo */
}

.navbar {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 15px 0;
  top: 0;
  z-index: 2;
}

.nav-links {
  display: flex;
  gap: 30px;
  list-style-type: none;
  padding: 0;
  margin: 0;
}

.nav-links a {
  text-decoration: none;
  font-weight: 500;
  position: relative;
  padding-bottom: 5px;
  transition: color 0.3s;
}

.nav-links a:hover {
  color: rgb(44, 44, 44);
}

.nav-links a::after {
  content: '';
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 2px;
  background-color: rgb(44, 44, 44);
  transform: scaleX(0);
  transform-origin: right;
  transition: transform 0.3s;
}

.nav-links a:hover::after, .nav-links a:focus::after {
  transform: scaleX(1);
  transform-origin: left;
}

.services, .contact-us {
  text-align: center;
  max-width: 1200px;
  margin: 0 auto;
  padding: 40px 20px;
}

.services h2 {
  font-size: 32px;
  margin-bottom: 10px;
}

.services .service-cards {
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
  gap: 20px; /* This adds space between the cards if they wrap */
}

.service-card {
  width: calc(33.333% - 20px); /* Adjusts for three cards in a row with space in between */
  border: solid 1px #e6e6e6; /* or the border color you see in your image */
  border-radius: 10px; /* Rounded corners as per your image */
  padding: 20px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1); /* subtle shadow */
  transition: box-shadow 0.3s ease-in-out;
}

.service-card:hover {
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.15); /* slightly larger shadow on hover */
}

.service-icon {
  font-size: 48px; /* larger icons as in your image */
  margin-bottom: 20px;
}

.service-card strong {
  display: block; /* makes it a block element */
  font-size: 20px;
  margin-bottom: 10px;
  color: #333; /* or the color you see in your image */
}

.service-card p {
  color: #666; /* a lighter shade for the paragraph text */
  line-height: 1.5;
}

/* Responsive adjustments for smaller screens, if needed */
@media (max-width: 768px) {
  .service-card {
    width: calc(50% - 20px); /* two cards per row on smaller screens */
  }
}

@media (max-width: 480px) {
  .service-card {
    width: 100%; /* one card per row on smallest screens */
  }
}


.mission-statement {
  font-size: 1em;          /* Increase the size */
  font-weight: 200;          /* Bold */
  text-align: center;        /* Centered text */
  padding: 20px;             /* Padding for space */
  background-color: #333;    /* Charcoal background */
  margin: 40px 0;            /* Space above and below */
  border-radius: 10px;       /* Rounded edges */
  box-shadow: 0px 3px 15px rgba(0, 0, 0, 0.1); /* Subtle shadow */
  max-width: 80%;            /* Ensuring not too wide */
  margin-left: auto;         /* Centering block */
  margin-right: auto;        /* Centering block */
}

/* Optional: Animation to fade in */
@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateY(20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.mission-statement {
  animation: fadeIn 1s forwards;
}

.submit-btn.loading::before {
  content: '';
  display: inline-block;
  height: 18px; /* Adjust size as required */
  width: 18px;
  border: 2px solid #333; /* Spinner color */
  border-radius: 50%;
  border-top: 2px solid transparent; /* Making the top transparent gives the spinner appearance */
  margin-right: 10px; /* Space between spinner and text */
  animation: spinner 1s linear infinite;
}

@keyframes spinner {
  100% {
    transform: rotate(360deg);
  }
}

.submit-btn[disabled] {
  cursor: default;
}

.error-message {
  color: red;
  font-weight: bold;
}


.generator {
  padding: 40px 20px;
  border-radius: 40px;
  background-color: #f7f4f1; /* Soft orange background */
  text-align: center;
}

.generator-form {
  margin-top: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
}

.generator-form input {
  padding: 15px;
  margin: 10px;
  border: 1px solid #ccc; /* subtle border */
  border-radius: 5px;
  width: 80%; /* Responsive input width */
  outline: none;
  font-size: 16px; /* Increase font size for readability */
}

.submit-btn {
  padding: 15px 30px;
  border: 2px solid rgb(110, 110, 110); /* Border color */
  border-radius: 5px;
  background-color: transparent; /* Transparent background */
  color: rgb(110, 110, 110);
  cursor: pointer;
  transition: background-color 0.3s;
  font-weight: bold; /* Make the button text stand out */
  margin-top: 10px;
}

.submit-btn:not([disabled]):hover {
  background-color: rgb(110, 110, 110); /* Fill button with color on hover */
  color: white;
}

.submit-btn.loading {
  pointer-events: none; /* Prevents clicks while loading */
}

.submit-btn[disabled] {
  background-color: #ccc; /* Gray out button when disabled */
  color: #666;
  cursor: not-allowed;
}


.error-message {
  color: #a94442; /* Error color */
  margin-top: 10px;
}

.site-footer {
  border-radius: 40px;
  background-color: #f7f4f1; /* Soft orange background */
  padding: 40px 20px;
  font-size: 0.9em;
}

.footer-content {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  margin-bottom: 20px;
}

.footer-about,
.footer-links,
.footer-contact {
  flex: 1;
  min-width: 200px;
  margin-bottom: 20px;
}

.site-footer h3 {
  margin-bottom: 10px;
  font-size: 1.2em;
}

.site-footer p, .site-footer ul {
  margin-bottom: 10px;
}

.site-footer a {
  text-decoration: none;
}

.site-footer a:hover {
  text-decoration: underline;
}

.footer-links ul {
  list-style: none;
  padding: 0;
}

.footer-links li {
  margin-bottom: 5px;
}

.footer-bottom {
  text-align: center;
  margin-top: 20px;
  border-top: 1px solid;
  padding-top: 20px;
}

/* Responsive styling */
@media (max-width: 768px) {
  .footer-content {
      flex-direction: column;
      align-items: center;
  }

  .footer-about, .footer-links, .footer-contact {
      text-align: center;
      margin-bottom: 30px;
  }
}
